import { Key } from 'vue-model';
import { EventEmitter } from './utils/EventEmitter';
import { Board, Slide, User } from './models';
import { Action } from './actions/Action';
import { UNDO_STEPS } from './config';

export class History extends EventEmitter {
  static instance = new History();

  /* eslint-disable no-use-before-define */
  user!: User;
  actionTable = new Map<Key, Action>();
  undoStack: [string, Action][] = [];
  redoStack: [string, Action][] = [];
  slide!: Slide;
  board!: Board;
  /* eslint-enable */

  constructor() {
    super();

    this.on('execute', (action: Action, userId: string) => {
      if (this.actionTable.has(userId)) {
        this.actionTable.get(userId)?.add(action);
      } else {
        // if (!action.noHistory) {
        //   this.pushToStack([ userId, action ])
        // }
        this.emit('actionExecuted', action, userId === this.user?._id);
      }
    });

    this.on('start', (action: Action, userId: string) => {
      // If an action of the user is active, we add it as subaction
      if (this.actionTable.has(userId)) {
        this.actionTable.get(userId)?.add(action);
      } else {
        // If not, we add an active action for the user
        this.actionTable.set(userId, action);
        // if (!action.noHistory) {
        //   this.pushToStack([ userId, action ])
        // }
      }
    });

    this.on('stop', (action: Action, userId: string) => {
      if (this.actionTable.has(userId) && this.actionTable.get(userId) === action) {
        this.actionTable.delete(userId);
        this.emit('actionExecuted', action, userId === this.user._id);
      }
    });

    this.on('cancel', (action: Action, userId: string) => {
      if (this.actionTable.has(userId)) {
        this.cancelAction(action, userId);
        this.emit('actionCanceled', action, userId === this.user._id);
      } else {
        throw new Error(`The action ${action.constructor.actionName} cannot be canceled. No action is active!`);
      }
    });
  }

  pushToStack(step: [userId: string, action: Action], undoRedo = false) {
    this.undoStack.push(step);
    this.ensureStepLimit();
    if (!undoRedo) this.redoStack = [];
  }

  ensureStepLimit() {
    const deleteCnt = this.undoStack.length - UNDO_STEPS;
    if (deleteCnt < 1) return;
    this.undoStack.splice(0, deleteCnt);
  }

  setLocalUser(user: User) {
    this.user = user;
  }

  cancelAction(action: Action, userId: string) {
    action.solidifyChangeSet();
    action.executeUndo();
    this.actionTable.delete(userId);
  }

  undo() {
    if (this.actionTable.has(this.user._id)) {
      return console.warn('Undoing not possible as long as local action is active');
    }

    if (!this.undoStack.length) return;

    // Loop through actions until finding a local one

    const [, action] = this.undoStack.pop() as [string, Action];

    // Throw away everything we dont need
    // if (userId !== this.user.id && !this.user.isMod) {
    //   continue
    // }

    action.executeUndo();
    // this.redoStack.push([ userId, action ])

    this.emit('undoAction', action);
  }

  redo() {
    if (this.actionTable.has(this.user._id)) {
      return console.warn('Redoing not possible as long as local action is active');
    }

    if (!this.redoStack.length) return;

    // We dont need to check if the user is allowed to redo
    // Only undoable actions land on the redo stack
    const [, action] = this.redoStack.pop() as [string, Action];
    action.executeRedo();

    // this.pushToStack([ userId, action ], true)
    this.emit('redoAction', action);
    // this.emit('actionExecuted', action, userId === this.user?._id)
  }

  setActiveSlide(slide: Slide) {
    this.slide = slide;
  }

  setActiveBoard(board: Board) {
    this.board = board;
  }

  getActiveSlide() {
    return this.slide;
  }

  getActiveBoard() {
    return this.board;
  }

  undoById(actionId: string) {
    // if (this.actionTable.has(this.user._id)) {
    //   return console.warn('Undoing not possible as long as local action is active')
    // }

    if (!this.undoStack.length) return;

    const [userId, action] = this.undoStack.pop() as [string, Action];
    if (action.id !== actionId) {
      this.undoStack.push([userId, action]);
      throw new Error(`Action with id ${actionId} was not on top of the undo stack`);
    }

    this.emit('undoAction', action, true);
    this.board.setSlide(this.board.order.indexOf(action.slideId));
    action.executeUndo();
    this.redoStack.push([userId, action]);
  }

  redoById(actionId: string) {
    // if (this.actionTable.has(this.user._id)) {
    //   return console.warn('Undoing not possible as long as local action is active')
    // }

    if (!this.redoStack.length) return;

    const [userId, action] = this.redoStack.pop() as [string, Action];
    if (action.id !== actionId) {
      this.redoStack.push([userId, action]);
      throw new Error(`Action with id ${actionId} was not on top of the redo stack`);
    }

    this.emit('redoAction', action, true);
    action.executeRedo();
    this.board.setSlide(this.board.order.indexOf(action.slideId));
    this.pushToStack([userId, action], true);
  }

  resetStacks() {
    this.undoStack = [];
    this.redoStack = [];
  }
}

export const history = () => History.instance;
