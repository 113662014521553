import { Node } from '../models';
import { Action } from './';

type NodesDeleteActionObjects = { nodes: Node[] };
export class NodesDeleteAction extends Action<NodesDeleteActionObjects> {
  static actionName = 'NodesDeleteAction';

  async redo({ nodes }: NodesDeleteActionObjects) {
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i]!;
      node.delete(true, (m) => this.delete(m));
    }
  }
}
