import { get, update } from 'idb-keyval';
import { nextTick } from 'vue';
import { Dehydrate } from 'vue-model';
import { Action, SlideDeleteAction, SlidePasteAction, SlideRenameAction } from './actions';
import { Board, Slide, Group, Participant, Node, User } from './models';
import { cleanUpStateChangeSet, mergeChangeSet } from './utils/utils';
import { AnswerHelloUpdate, AnswerHello, ChangeSet, ChangeSetNonOptional } from './utils/WebSocketMessage';
import { history } from './History';
import { useWebSocket } from './compositions/useWebSocket';
import * as store from './utils/store';

export function makeReadonly(boardId: string, readonly = true) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "makeReadonly": boardId "${boardId}" doesn't exist`);
  board.readonly = readonly;
  board.readonly && (board.pointers = {});

  // Reset the selected whiteboard tool to "select" so that the cursor will be default when whiteboard control is taken away
  board.toolbar = 'select';
}

export function disableNavigation(boardId: string, navigationEnabled = false) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "disableNavigation": boardId "${boardId}" doesn't exist`);
  board.navigationEnabled = navigationEnabled;
}

export function participantCanDraw(participantId: string, canDraw = true) {
  const participant = Participant.get(participantId);
  if (!participant) throw new Error(`Error in "participantCanDraw": participantId "${participantId}" doesn't exist`);
  participant.canDraw = canDraw;
}

export function participantCanNavigate(participantId: string, canNavigate = true) {
  const participant = Participant.get(participantId);
  if (!participant)
    throw new Error(`Error in "participantCanNavigate": participantId "${participantId}" doesn't exist`);
  participant.canNavigate = canNavigate;
}

export function makeOwner(participantId: string, isOwner = true) {
  const participant = Participant.get(participantId);
  if (!participant) throw new Error(`Error in "makeOwner": participantId "${participantId}" doesn't exist`);
  participant.isOwner = isOwner;
}

export function navigate(boardId: string, index: string | number) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "navigate": boardId "${boardId}" doesn't exist. Index is "${index}".`);

  board.setSlide(Number(index).valueOf());
}

export function next(boardId: string) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "next": boardId "${boardId}" doesn't exist`);

  board.nextSlide();
}

export function prev(boardId: string) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "prev": boardId "${boardId}" doesn't exist`);

  board.prevSlide();
}

export async function deleteSlide(boardId: string, overRide = false) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "deleteSlide": boardId "${boardId}" doesn't exist`);

  // Make the user confirm that they want to do this.
  if (!overRide) {
    const result = await window.confirmMe('Delete Board?');
    if (!result) return;
  }

  const action = new Action().start();

  // If there is only one slide in the board set insert a blank one so we still end up with a default slide.
  if (board.slides.length < 2) {
    const defaultSlide = Slide.create({ board: board, name: 'Board 1' });
    await new SlidePasteAction().execute({ slide: board.firstSlide as Slide, copy: defaultSlide }, 'after');
  }

  const slide = board.currentSlide as Slide;

  // if (board.currentIndex > board.slides.length - 2) {
  //   navigate(boardId, board.currentIndex - 1)
  // }

  await new SlideDeleteAction().execute({ slide });

  action.stop();
}

export async function deleteAllSlides(boardId: string) {
  // Make sure the incoming boardId is a valid board.
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "deleteSlide": boardId "${boardId}" doesn't exist`);

  // Make the user confirm that they want to do this.
  const result = await window.confirmMe('Delete all boards in this room?');
  if (!result) return;

  // Extra protection to be sure we don't delete ALL slides.
  if (board.slides.length < 2) return;

  // For setting messaging about the fact that we're deleting a set of boards.
  window.boardSetDeleting(true);

  const action = new Action().start();

  // Insert a blank slide at the very beginning so that all of the requested boards to
  // be deleted actually do get deleted, but we still end up with a defaul blank board.
  const defaultSlide = Slide.create({ board: board, name: 'Board 1' });
  await new SlidePasteAction().execute({ slide: board.firstSlide as Slide, copy: defaultSlide }, 'before');

  // Ensure don't delete the first slide that belongs to this board.
  const slideSet = board.slides.slice().reverse();
  slideSet.pop();

  // Perform the deletion of the "slide set".
  await Promise.all(
    slideSet.map((slide) => {
      // We can await the promise or just return it
      // It doesn't make much of a difference here but looks cleaner like this
      return new SlideDeleteAction().execute({ slide });
    }),
  );

  action.stop();

  // For setting messaging about the fact that we're deleting a set of boards.
  setTimeout(() => {
    // setTimeout() is needed for some reason for window.boardSetDeleting(true) to even display
    window.boardSetDeleting(false);
  }, 1000);
}

export function addSlide(boardId: string) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "addSlide": boardId "${boardId}" doesn't exist`);

  const slide = Slide.create({ board: board, name: 'Board ' + (board.slides.length + 1) });
  new SlidePasteAction().execute({ slide: board.currentSlide as Slide, copy: slide }, 'after');
  // navigateTo(board.value.slides.length ?? 1)
  navigate(boardId, board.currentIndex + 1);
}

export function setCopiedSlide(boardId: string, slide: Dehydrate<typeof Slide>) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "setCopiedSlide": boardId "${boardId}" doesn't exist`);
  slide.boardId = boardId;

  const setBoardId = (nodes: Node[]) => {
    nodes.forEach((node) => {
      if ((node as Group).nodes) {
        setBoardId((node as Group).nodes);
      }
      node.boardId = boardId;
    });
  };

  setBoardId(slide.nodes as Node[]);

  board.copiedSlide = slide;
}

export function renameSlide(boardId: string, index: number, name: string) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "renameSlide": boardId "${boardId}" doesn't exist`);

  if (!board.slides[index]) throw new Error(`Error in "renameSlide": slide with index  "${index}" doesn't exist`);

  new SlideRenameAction().execute({ slide: board.slides[index] }, name);

  window.slideNames(board.slides);
}

export function addUsers(userInfo: Partial<User>[]) {
  userInfo.forEach((user) => {
    User.fillOrCreate(user);
  });
}

export async function load(boardId: string, userInfo: Partial<User> = new User({ moderator: import.meta.env.DEV })) {
  const user = User.getOrCreate(userInfo);
  history().setLocalUser(user);

  const board = Board.get(boardId);
  if (board) {
    board.localUserId = user._id;
  }

  const changeset = (await get('board-' + boardId)) as ChangeSet;
  if (!changeset) return request(boardId);

  const actionId = store.get('actionId-' + boardId);

  if (!actionId || !changeset) {
    return request(boardId);
  }

  request(boardId, actionId, changeset);
}

export function reset(boardId: string) {
  const board = Board.get(boardId);
  if (!board) throw new Error(`Error in "reset": boardId "${boardId}" doesn't exist`);

  store.remove('actionId-' + boardId);

  Slide.all().forEach((slide) => {
    slide.delete();
  });
  board.fill(new Board({ _id: board._id, localUserId: board.localUserId }).toObject());

  request(boardId);
}

const isUpdate = (message: AnswerHelloUpdate | AnswerHello): message is AnswerHelloUpdate => {
  return (message as any).update;
};

const request = async (boardId: string, actionId?: string, changeset?: ChangeSet) => {
  const board = Board.get(boardId);

  if (!board) {
    throw new Error(`Error in "request": boardId "${boardId}" doesn't exist`);
  }

  const ws = useWebSocket(boardId, false);
  const message = await ws.request('hello', { boardId, lastActionId: actionId, userId: history().user._id });

  history().user._id = message.user._id!;

  let set: ChangeSet = {};
  if (isUpdate(message)) {
    Action.applyChangeSet(changeset as ChangeSetNonOptional);
    set = message.state ?? {};
  } else {
    set = message.state;
  }

  await update('board-' + boardId, (changeSet: ChangeSet | undefined) => {
    if (!isUpdate(message)) {
      return set;
    } else {
      return cleanUpStateChangeSet(mergeChangeSet(set as ChangeSetNonOptional, changeSet as ChangeSetNonOptional));
    }
  });

  store.set('actionId-' + boardId, message.lastActionId);
  board.seq = message.seq;

  Action.applyChangeSet(set as ChangeSetNonOptional);

  if (message.undo) {
    history().undoStack = message.undo.map(({ actionId, slideId, changeSet }) => [
      actionId,
      Action.fromWSMessage({
        id: actionId,
        changeSet,
        slideId,
        type: 'action',
        seq: 0,
      }),
    ]);
  }

  if (message.redo) {
    history().redoStack = message.redo.map(({ actionId, slideId, changeSet }) => [
      actionId,
      Action.fromWSMessage({
        id: actionId,
        changeSet,
        slideId,
        type: 'action',
        seq: 0,
      }),
    ]);
  }

  history().setActiveSlide(board.currentSlide as Slide);
  history().setActiveBoard(board);

  nextTick(() => {
    window.readyHandler();
  });
};
